import logo from './cobrandlogos.png';
import logoWithPiP from './cobrandlogosWithPiP.svg';
import { Box } from '@mui/material';

export const CobrandLogo = () => {
  return (
    <Box sx={{ width: { xs: 264, sm: 353 }, '&>img': { width: '100%', height: '100%' } }}>
      <img src={logo} alt="co-brand logo" width={353} height={85} />
    </Box>
  );
};

export const CobrandLogoWithPiP = ({ sx }: { sx?: Parameters<typeof Box>[0]['sx'] }) => {
  return (
    <Box sx={{ '&>img': { width: '100%', height: '100%' }, ...sx }}>
      <img src={logoWithPiP} alt="co-brand logo" />
    </Box>
  );
};
