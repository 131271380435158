import { useState, useCallback } from 'react';

export type AsyncState = {
  status?: 'pending' | 'failure' | 'fulfilled';
  error?: any;
};

type Success = () => void;
type Error = (reason: any) => void;
type Waiting = () => void;

export const useAsyncState = (): [AsyncState, Success, Error, Waiting] => {
  const [state, setState] = useState<AsyncState>({});
  const success = useCallback(() => setState({ status: 'fulfilled' }), []);
  const error = useCallback((reason: any) => setState({ status: 'failure', error: reason }), []);
  const waiting = useCallback(() => setState({ status: 'pending' }), []);
  return [state, success, error, waiting];
};
